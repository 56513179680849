import { Button, Divider, Form, Input, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import DefaultUpload from "../../components/uploads/DefaultUpload";
import { useMaterialPost } from "../../Hooks/materials/useMaterials.query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import generateUuid from "../../helpers/generateUuid";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};

const CreateMaterials = () => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [callbackResponse, setCallbackResponse] = useState("");

  const handleUploadComplete = (data) => {
    setCallbackResponse(data);
  };

  const { mutate, isLoading: sendingForm } = useMutation(useMaterialPost, {
    onSuccess: () => {
      queryClient.invalidateQueries("materials");
      api.open({
        message: "Sucesso",
        description: "sucesso."
      });
      form.resetFields();
      navigate("/materials");
    }
  });

  const onFinish = (formValues) => {
    const data = {
      ...formValues,
      file: callbackResponse?.imageUrl,
      url_file: callbackResponse?.imageUrl,
      type: "material"
    };

    mutate(data);
  };

  return (
    <>
      <Title level={4}>Novo material</Title>
      <Divider />

      <Form
        {...layout}
        layout="horizontal"
        form={form}
        name="create-user"
        onFinish={onFinish}
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Form.Item name="title" label="Titulo" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="file" label="Arquivo">
          <DefaultUpload
            title={generateUuid()}
            label={`Enviar arquivo`}
            folder={`materials/materials-managers/`}
            controller="material"
            action="uploadmaterials"
            callback={handleUploadComplete}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            loading={!!sendingForm}
            style={{ width: "100%", marginRight: 0 }}
            type="primary"
            htmlType="submit"
          >
            CRIAR
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateMaterials;
